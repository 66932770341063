.container {
  width: 70%;
  margin: 40px auto;
  padding: 20px;
  margin-top: 7rem;
  box-sizing: border-box;
}

.globalHeading {
  font-size: 2.1rem;
  margin-bottom: 0px;
  text-align: left; /* Align the heading text to the left */
  width: 100%; /* Ensure it takes full width of the container */
  color: white;
  margin-left: 1rem;
  font-weight: 400;
  font-family: 'inter';
}

.sliderContainer {
  width: 100%;
  margin: 0 auto;
}

.overallBox {
  background-color: #8400ffad;
  padding: 5px;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
}

.carouselItem {
  display: flex;
  justify-content: center; /* Center the sub-boxes horizontally */
  padding: 5px;
}

.subBox {
  background-color: #0808085d;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80px;
  width: 400px;
  margin-bottom: 15px;
  color: white; /* Changed text color to white */
}

.subBoxHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.subBoxHeader p {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
}

.notificationCircle {
  background-color: #ffffff;
  color: #7b05e9;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  margin-top: 2px; /* Reduce gap between date and circle */
}

.subBoxContent {
  width: 100%;
  display: flex;
  align-items: flex-start; /* Align content to the top */
  justify-content: center; /* Center content horizontally */
  margin-bottom: 100px; /* Add padding to adjust the vertical position */
}

.unreadMessageWidget,
.notificationsWidget,
.upcomingDeadlineWidget {
  text-align: center;
  font-size: 1rem;
}

.currentJobsContainer {
  background-color: #45454531;
  color: white; /* Changed text color to white */
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  width: 100%;
}

.currentJobsHeader {
  background-color: #ff000000; /* Header background color */
  color: white;
  padding: 10px;
  text-align: center;
  font-size: 1.5rem;
  margin: 0; /* Remove margins */
  border-radius: 10px 10px 0 0;
  text-align: left;
  margin-left: 10px;
  font-weight: 700;
}

.currentJobsContent {
  padding: 20px; /* Apply padding to the content container */
}

.jobsList {
  list-style-type: none;
  padding: 0;
}

.jobItem {
  margin-bottom: 10px;
}

.jobBox {
  background-color: #39353e69;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 140%;
}

.jobTitle {
  flex-grow: 1;
}

.jobIcons {
  display: flex;
  gap: 10px;
}

.jobIcon {
  cursor: pointer;
  color: white;
  scale: 140%;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #7b05e9; /* Background color of the circle */
  border-radius: 50%; /* Make the icon circular */
  padding: 10px; /* Adjust padding as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px; /* Fixed width for the circle */
  height: 40px; /* Fixed height for the circle */
}

.calendarAppliedContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.calendarWidgetContainer {
  flex: 1;
  background-color: #45454551;
  padding: 10px 20px; /* Reduced padding */
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-right: 10px;
  height: auto;
  color: white; /* Changed text color to white */
}

.calendarHeading {
  font-size: 1.5rem;
  margin-bottom: 10px; 
  margin-top: 0px;/* Reduced gap between heading and edge of the box */
}

.appliedJobsWidgetContainer {
  flex: 2;
  background-color: #45454551;
  color: white; /* Changed text color to white */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: auto;
}

.weekContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.dayBox {
  flex: 1;
  background-color: #4285f4;
  color: white;
  padding: 5px 10px; /* Reduced padding to adjust the vertical gap */
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  margin-right: 5px; /* Reduced margin */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1.5%;
}

.dayBox:last-child {
  margin-right: 0;
}

.dayName {
  font-size: 1.2rem; /* Larger font size for the day name */
  margin-bottom: 0px; /* Reduced spacing */
  margin-top: 2px; /* Reduced spacing from the top */
}

.dayDate {
  font-size: .65rem; /* Smaller font size for the month and date */
  margin-top: 2px; /* Reduced spacing between day name and date */
}

.selectedDay {
  background-color: #8400ffa6;
}

.weekNavigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  color: white; /* Changed text color to white */
}

.navigationIcon {
  cursor: pointer;
  margin: 0 10px;
  color: white; /* Changed icon color to white */
}

.agendaContainer {
  background-color: #39353e69;
  color: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.agendaContainer h3 {
  margin-top: 0;
}

.pastJobsContainer {
  background-color: #45454551;
  color: white; /* Changed text color to white */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  width: 100%;
}

.pastJobsWidget {
  text-align: center;
  font-size: 1rem;
}

.pastJobsWidget h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.pastJobsWidget ul {
  list-style-type: none;
  padding: 0;
}

.pastJobsWidget li {
  padding: 5px 0;
}

.customDots {
  display: flex !important;
  justify-content: center;
  padding: 10px 0;
  margin: -10px;
}

.customDots li {
  display: inline-block;
  margin: 0 2px;
}

.customDots li button {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
}

.customDots li button::before {
  content: '•';
  font-size: 24px;
  color: #ddd;
}

.customDots li.react-multi-carousel-dot--active button::before {
  color: #000;
}

.customArrow {
  background: rgba(0, 0, 255, 0);
  border: none;
  outline: none;
  cursor: pointer;
  width: 60px;
  height: 100%;
  
  position: absolute;
  z-index: 1;
}

.leftArrow {
  left: 0;
}

.rightArrow {
  right: 0;
}
