/* General Styles for the Form */
.job-posting-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #000000;
  border-radius: 8px;
  overflow-y: auto;
  margin-top: 1.5rem;
  padding: 20px;
  position: relative;
  width: 100%;
}

.job-posting-form__form {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #000000;
  padding: 20px;
  border-radius: 8px;
}

.job-posting-form__button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.job-posting-form__save-button,
.job-posting-form__close-button {
  background-color: #110c0b;
  color: #ffffff;
  padding: 5px 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: absolute;
  top: 50px;
  right: 120px;
  font-size: 0.8rem;
}

.job-posting-form__save-button {
  right: 200px;
}

.job-posting-form__close-button:hover {
  background-color: #ff6347;
}

.job-posting-form__save-button:hover {
  background-color: #32cd32;
}

.job-posting-form__title {
  font-size: 3.5rem;
  color: #ffffff;
  margin-bottom: 20px;
  margin-top: 100px;
  margin-left: 20%;
  font-weight: 240;
  align-items: flex-start;
  text-align: left;
}

.job-posting-form__paragraph {
  font-size: 1.51rem;
  color: #ffffff;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 20%;
  font-weight: 240;
  align-items: flex-start;
  text-align: left;
  position: relative;
  padding-left: 20px;
}

.job-posting-form__paragraph::before {
  content: '◉ ';
  color: #7d4cdb;
  position: absolute;
  left: 0;
  top: 0;
}

.job-posting-form__field {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.job-posting-form__label {
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 5px;
}

.job-posting-form__input,
.job-posting-form__textarea,
.job-posting-form__select,
.job-posting-form__file-input {
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 8px;
  background-color: #33333354;
  color: #ffffff;
}

.job-posting-form__textarea {
  height: 100px;
  resize: none;
}

.job-posting-form__next-button,
.job-posting-form__prev-button,
.job-posting-form__submit-button,
.job-posting-form__back-button {
  background-color: #7d4cdb;
  color: #ffffff;
  padding: 10px 30px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  font-size: 1.24rem;
  font-weight:400;
  font-family: 'inter';
}

.job-posting-form__next-button:hover,
.job-posting-form__prev-button:hover,
.job-posting-form__submit-button:hover,
.job-posting-form__back-button:hover {
  background-color: #28a745;
}

.job-posting-form__prev-button {
  background-color: #ff6347;
}

.job-posting-form__prev-button:hover {
  background-color: #e55342;
}

.Required {
  color: rgba(255, 255, 255, 0.315);
  text-align: right;
}

.Required::before {
  content: '* ';
  color: rgb(255, 0, 0);
  margin-left: 5px;
}

/* Tooltip Styles */
.job-posting-form__tooltip {
  position: relative;
  display: inline-block;
  margin-left: 10px;
}

.job-posting-form__tooltip .job-posting-form__tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
}

.job-posting-form__tooltip:hover .job-posting-form__tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* Step Container */
.job-posting-form__step-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
}
.job-posting-form__button-group-step1 {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 100px; /* Adjust this value as needed */
}

.job-posting-form__button-group-step1 .job-posting-form__next-button {
  margin-left: auto;
}

/* Step Section */
.job-posting-form__section {
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
}

.job-posting-form__column {
  flex: 1;
  padding: 20px;
}

.job-posting-form__column-heading {
  position: sticky;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 20px;
  margin-top: 100px;
}

.job-posting-form__column-content {
  height: 100%;
  overflow-y: auto;
  padding-left: 20px;
  margin-top: 120px;
  margin-right: 100px;
}

/* Styles for Project Type and Tools */
.job-posting-form__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
*/*collum left is actually the right collum for section 2*/
.job-posting-form__column-left { 
  flex: 2;
  padding: 0 125px;
  margin-top: 200px;
  max-width: 60%;
}

.job-posting-form__column-right {
  flex: 1;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  gap: 5px; /* Increase this value to increase the gap */
}

.job-posting-form__tool-input-container,
.job-posting-form__tag-input-container {
  display: flex;
  align-items: center;
}

.job-posting-form__input-with-button {
  position: relative;
  display: flex;
  align-items: center;
}

.job-posting-form__input {
  flex: 1;
  padding-right: 40px;
}

.job-posting-form__add-button {
  position: absolute;
  right: 10px;
  background-color: #32cd3200;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
}

.job-posting-form__tool-list,
.job-posting-form__tag-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.job-posting-form__tool-item,
.job-posting-form__tag-item {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7d4cdb;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  padding: 4px 18px 4px 10px;
}

.job-posting-form__remove-button {
  margin-left: 5px;
  background-color: transparent;
  border: none;
  color: rgb(255, 255, 255);
  width: 20px;
  cursor: pointer;
  position: absolute;
  top: 49%;
  right: 1px;
  transform: translateY(-40%);
}

.job-posting-form__remove-button i {
  font-size: 14px;
}

/* Styles for Job Post Type and Project Length */
.job-posting-form__icons {
  display: flex;
  justify-content: space-around;
  margin: 1em 0;
}

.job-posting-form__icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border: 2px solid transparent;
  border-radius: 10px;
  transition: border-color 0.3s, background-color 0.3s;
}

.job-posting-form__icon-container:hover {
  border-color: #7d4cdb;
}

.job-posting-form__icon {
  font-size: 2em;
}

.job-posting-form__icon-text {
  margin-top: 0.5em;
  font-size: 1em;
}

.job-posting-form__icon-container.selected {
  border-color: #7d4cdb;
  background-color: rgba(125, 76, 219, 0.2);
}

/* Styles for File Drop */
.job-posting-form__file-drop {
  border: 2px dashed #7d4cdb;
  border-radius: 8px;
  background-color: #f7f7f700;
  color: #333333;
  text-align: center;
  padding: 20px;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.job-posting-form__file-drop.dragover {
  border-color: #32cd32;
  background-color: #e6ffe600;
  transition: color 0.3s ease;
}

.job-posting-form__file-drop:hover {
  background-color: #eeeeee00;
  border-color: #ffffff;
}

.job-posting-form__file-drop-icon {
  font-size: 48px;
  color: #7d4cdb;
  margin-bottom: 10px;
}

.job-posting-form__file-drop-icon:hover {
  font-size: 48px;
  color: #ffffff;
  margin-bottom: 10px;
  transition: color 0.3s ease;
}

.job-posting-form__file-drop-text {
  font-size: 18px;
  color: #ffffff;
}

.job-posting-form__file-drop-input {
  display: none;
}

.job-posting-form__image-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.job-posting-form__image-container {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 5px;
}

.job-posting-form__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  border: 2px solid #7d4cdb;
}

.job-posting-form__remove-image-button {
  position: absolute;
  top: 5px;
  left: 75px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  padding: 0;
}

.job-posting-form__remove-image-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

/* Styles for Timeline */
.job-posting-form__timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  padding: 0 20px;
}

.job-posting-form__timeline-item {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.job-posting-form__timeline-date {
  font-size: 14px;
  color: #ffffff;
  margin-right: 10px;
}

.job-posting-form__timeline-node {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #7d4cdb;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.job-posting-form__timeline-node.current {
  background-color: #32cd32;
}

.job-posting-form__timeline-node.post-live {
  background-color: #ffa500;
}

.job-posting-form__timeline-node.auction-end {
  background-color: #ff6347;
}

.job-posting-form__timeline-node.project-end {
  background-color: #1e90ff;
}

.job-posting-form__timeline-line {
  width: 2px;
  background-color: #7d4cdb;
  margin-left: 23.2%;
}

.job-posting-form__timeline-line.post-live {
  background-color: #ffa500;
}

.job-posting-form__timeline-line.auction-end {
  background-color: #ff6347;
}

.job-posting-form__timeline-line.project-end {
  background-color: #1e90ff;
}

.job-posting-form__timeline-end {
  font-size: 14px;
  color: #ffffff;
  background-color: #7d4cdb;
  padding: 5px 10px;
  border-radius: 8px;
  margin-top: 10px;
}

/* Styles for Review Post */
.job-posting-form__review {
  max-width: 1400px;
  min-width: 800px;
  margin: 0 auto;
  padding: 20px;
  color: white;
  background-color: #00000000;
}

.compNumber {
  font-size: 5rem;
  margin: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.job-posting-form__header {
  margin-bottom: 20px;
}

.job-posting-form__project-title {
  font-size: 2.5rem;
  margin-bottom: 5px;
}

.job-posting-form__subline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  color: #ffffffcd;
}

.company-name {
  margin: 0;
}

.sublineRight {
  margin-left: auto;
  margin-right: 20px;
  font-size: 1.25rem;
  color: white;
  margin-top: -40px;
}

.sublineRight .project-type,
.sublineRight .job-post-type {
  font-size: inherit;
  font-weight: bold;
}

.dot {
  color: #7d4cdb;
  font-size: 1.2rem;
}

.job-posting-form__content {
  display: flex;
  gap: 20px;
}

.job-posting-form__main-content {
  flex: 2;
  min-width: 500px;
}

.job-posting-form__carousel {
  position: relative;
  background-color: #2a2a2a19;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.job-posting-form__carousel-image-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.job-posting-form__carousel-image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
  display: block;
}

.job-posting-form__carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 18px;
  z-index: 10;
}

.job-posting-form__carousel-button-left {
  left: 10px;
}

.job-posting-form__carousel-button-right {
  right: 10px;
}

.job-posting-form__project-overview {
  background-color: #2a2a2a00;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
}

.job-posting-form__project-overview h3 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1.5rem;
}

.job-posting-form__project-overview p {
  font-size: 1rem;
  line-height: 1.6;
}

.job-posting-form__sidebar {
  flex: 1;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #1a1a1a00;
  padding: 15px;
  border-radius: 8px;
}

.job-posting-form__sidebar-section {
  background-color: #2a2a2a19;
  padding: 15px;
  border-radius: 8px;
}

.job-posting-form__sidebar-section h4 {
  margin: 0 0 10px 0;
  font-size: 1rem;
  color: #ffffff;
  font-weight: normal;
}

.job-posting-form__compensation,
.job-posting-form__tools,
.job-posting-form__tags {
  margin: 0;
  font-size: 1rem;
  color: #ffffff;
  word-wrap: break-word;
}

.job-posting-form__compensation {
  font-size: 1.5rem;
  font-weight: bold;
}

  .job-posting-form__review {
    min-width: auto;
    padding: 10px;
  }

  .job-posting-form__content {
    flex-direction: column;
  }

  .job-posting-form__main-content,
  .job-posting-form__sidebar {
    min-width: auto;
  }

  .job-posting-form__project-title {
    font-size: 2rem;
  }

  .job-posting-form__subline {
    font-size: 1rem;
  }

  .job-posting-form__carousel {
    height: 300px;
  }

  .job-posting-form__button-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 125px; /* Adjust padding to align with content */
    margin-top: auto; /* Push buttons to the bottom */
  }
  .job-posting-form__button-group .job-posting-form__next-button {
    margin-left: auto;
  }
  
  .job-posting-form__button-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 125px; /* Adjust padding to align with content */
    margin-top: auto; /* Push buttons to the bottom */
  }
  
  .job-posting-form__back-button,
  .job-posting-form__next-button {
    background-color: #7d4cdb;
    color: #ffffff;
    padding: 10px 30px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1.24rem;
    font-weight: 400;
    font-family: 'inter';
    
  }
  
  .job-posting-form__back-button {
    background-color:#7d4cdb;
  }
  
  .job-posting-form__back-button:hover {
    background-color: #e55342;
  }
  
  .job-posting-form__next-button:hover {
    background-color: #28a745;
  }
  .job-posting-form__textarea-auto-grow {
    min-height: 100px;
    resize: none;
    overflow: hidden;
  }

  .job-posting-form__file-list-container {
    max-height: 200px;
    overflow-y: auto;
    border-radius: 4px;
    margin-top: 10px;
  }
  
  .job-posting-form__file-list {
    padding: 5px;
  }
  
  .job-posting-form__file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(125, 76, 219, 0.1);
    padding: 5px 10px;
    margin-bottom: 5px;
    border-radius: 4px;
    position: relative;
  }
  
  .job-posting-form__file-item span {
    word-break: break-all;
    margin-right: 25px; /* Make space for the remove button */
    flex-grow: 1;
  }

  
  .job-posting-form__remove-button:hover {
    color: #ff6347;
    border-radius: 50%;
  }
.job-posting-form__permissions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 0px;
}

.job-posting-form__permissions label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: #ffffff;
}

.job-posting-form__permissions input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #7d4cdb;
  border-radius: 50%;
  margin-right: 10px;
  outline: none;
  position: relative;
}

.job-posting-form__permissions input[type="radio"]:checked::before {
  content: '';
  width: 12px;
  height: 12px;
  background-color: #7d4cdb;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.job-posting-form__permissions input[type="radio"]:hover {
  border-color: #9b79e4;
}

.job-posting-form__permissions input[type="radio"]:checked {
  border-color: #7d4cdb;
}

.job-posting-form__permissions label:hover {
  color: #9b79e4;
}
.job-posting-form__deliverable-list-container {
  max-height: 200px;
  overflow-y: auto;
  border-radius: 4px;
  margin-top: 10px;
}

.job-posting-form__deliverable-list {
  padding: 5px;
}

.job-posting-form__deliverable-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(125, 76, 219, 0.1);
  padding: 5px 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  position: relative;
}

.job-posting-form__deliverable-item span {
  word-break: break-all;
  margin-right: 25px;
  flex-grow: 1;
}

.job-posting-form__deliverable-item .job-posting-form__remove-button {
  background: none;
  border: none;
  color: #f8f8f8;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  min-height: 20px;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.job-posting-form__deliverable-item .job-posting-form__remove-button:hover {
  color: #ff6347;
}

.job-posting-form__revisions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.job-posting-form__revisions label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: #ffffff;
  flex: 1;
}

.job-posting-form__revisions input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #7d4cdb;
  border-radius: 50%;
  margin-right: 10px;
  outline: none;
  position: relative;
  flex-shrink: 0;
}

.job-posting-form__revisions input[type="radio"]:checked::before {
  content: '';
  width: 12px;
  height: 12px;
  background-color: #7d4cdb;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.job-posting-form__revisions input[type="radio"]:hover {
  border-color: #9b79e4;
}

.job-posting-form__revisions input[type="radio"]:checked {
  border-color: #7d4cdb;
}

.job-posting-form__revisions label:hover {
  color: #9b79e4;
}

.job-posting-form__cost-input {
  position: relative;
  display: flex;
  align-items: center;
}

.job-posting-form__currency-symbol {
  position: absolute;
  left: 10px;
  color: #ffffff;
  font-size: 16px;
}

.job-posting-form__cost-input input {
  padding-left: 25px;
}
/* ... (previous styles) */

.job-posting-form__select {
  padding: 10px;
  width: 100%;
  border: 1px solid #ffffff00; /* Added border */
  border-radius: 8px;
  background-color: #111111; /* Slightly lighter black on hover/focus */
  color: #ffffff;
  font-size: 16px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%237d4cdb%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 12px auto;
}

.job-posting-form__select:hover,
.job-posting-form__select:focus {
  background-color: #111111; /* Slightly lighter black on hover/focus */
  border-color: #ffffff; /* Lighter purple border on hover/focus */
}

/* Style for dropdown options */
.job-posting-form__select option {
  background-color: #000000;
  color: #ffffff;
}
/* ... (previous styles) */

/* Calendar Input Styling */
.job-posting-form__input[type="datetime-local"] {
  padding: 10px;
  width: 100%;
  border-radius: 8px;
  background-color: #111111;
  color: #ffffff;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='3' y='4' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Cline x1='16' y1='2' x2='16' y2='6'%3E%3C/line%3E%3Cline x1='8' y1='2' x2='8' y2='6'%3E%3C/line%3E%3Cline x1='3' y1='10' x2='21' y2='10'%3E%3C/line%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 20px auto;
}

.job-posting-form__input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
  opacity: 0.7;
  cursor: pointer;
}

.job-posting-form__input[type="datetime-local"]:hover,
.job-posting-form__input[type="datetime-local"]:focus {
  background-color: #111111;
  border-color: #9b79e4;
}

/* Calendar Popup Styling */
::-webkit-datetime-edit { padding: 0; }
::-webkit-datetime-edit-fields-wrapper { background: transparent; }
::-webkit-datetime-edit-text { color: #7d4cdb; padding: 0 0.3em; }
::-webkit-datetime-edit-month-field { color: #ffffff; }
::-webkit-datetime-edit-day-field { color: #ffffff; }
::-webkit-datetime-edit-year-field { color: #ffffff; }
::-webkit-inner-spin-button { display: none; }
::-webkit-calendar-picker-indicator { background: transparent; }

/* Styling for Firefox */
.job-posting-form__input[type="datetime-local"]::-moz-calendar-picker-indicator {
  filter: invert(1);
  opacity: 0.7;
}

/* Calendar Popup Container */
.calendar-popup {
  background-color: #1a1a1a;
  border: 1px solid #7d4cdb;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: 'Inter', sans-serif;
}

/* Calendar Header */
.calendar-popup .header {
  background-color: #7d4cdb;
  color: #ffffff;
  padding: 10px;
  text-align: center;
  font-weight: bold;
}

/* Calendar Days */
.calendar-popup .day {
  color: #ffffff;
  padding: 5px;
  text-align: center;
}

.calendar-popup .day:hover {
  background-color: #7d4cdb;
  border-radius: 4px;
}

/* Selected Day */
.calendar-popup .selected {
  background-color: #7d4cdb;
  border-radius: 4px;
}

/* Time Input */
.calendar-popup .time-input {
  background-color: #000000;
  border: 1px solid #7d4cdb;
  color: #ffffff;
  padding: 5px;
  margin-top: 10px;
}
/* ... (previous styles) */

.job-posting-form__input.error,
.job-posting-form__textarea.error,
.job-posting-form__select.error {
  border: 2px solid #ff0909;
}
.job-posting-form__error-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.job-posting-form__error-popup-content {
  background-color: #000000;
  color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 2px solid #ffffff;
  position: relative;
}

.job-posting-form__error-popup-content h2 {
  color: #ffffff;
  margin-top: 0;
  font-size: 1.5em;
  padding-right: 30px; /* Make space for the close button */
}

.job-posting-form__error-popup-content ul {
  list-style-type: none;
  padding: 0;
}

.job-posting-form__error-item {
  color: #ff2600;
  margin-bottom: 10px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.job-posting-form__error-item:hover {
  color: #efcac5;
}

.job-posting-form__error-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1.5em;
  cursor: pointer;
  padding: 5px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.job-posting-form__error-close-button:hover {
  color: #ff2600;
  background-color: rgba(255, 255, 255, 0);
}