* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
}

.nav {
  background-color:#080808;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  flex-wrap: wrap;
  transition: background-color 0.3s ease;
}

.nav.semi-transparent {
  background-color: #08080841;
}

.nav .nav-left,
.nav .nav-right {
  display: flex;
  align-items: center;
}

.nav .nav-left a,
.nav .nav-right a {
  color: inherit;
  text-decoration: none;
  margin: 0 0.5rem;
  font-size: 0.85rem; /* Reduced font size */
  font-weight: 600;
  margin-top: 0; /* Ensure no top margin */
}

.nav .nav-left img {
  height: 30px;
  margin-right: 0.5rem;
  margin-top: 0; /* Ensure no top margin */
}

.nav-center {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav ul li {
  margin: 0 0.5rem;
}

.nav ul li a {
  color: #ffffff;
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  font-size: 0.85rem; /* Reduced font size */
  font-weight: 600;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0; /* Ensure no top margin */
}

.nav ul li a:hover {
  color: #7f4dca;
}

.profile-menu {
  position: relative;
  display: flex;
  align-items: center;
}

.profile-info {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align items to the right */
  cursor: pointer;
}

.profile-info span {
  font-size: 0.75rem; /* Adjusted font size for the username */
}

.profile-picture {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 0.5rem; /* Adjusted margin to separate picture from text */
  order: 2; /* Set order to place the picture on the right */
}

.dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 20;
  border-radius: 4px;
  overflow: hidden;
}

.dropdown a {
  display: block;
  color: white;
  padding: 0.5rem 1rem;
  text-decoration: none;
}

.dropdown a:hover {
  background-color: rgba(127, 77, 202, 0.8);
}

/* Media Queries */
@media (max-width: 1024px) {
  .nav {
    padding: 0.5rem 1rem;
  }
  .nav .nav-left img {
    height: 3ch;
    margin-right: 0.5rem;
    margin-top: 0;
  }
  .nav ul li a {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .nav {
    flex-direction: column;
    align-items: flex-start;
  }
  .nav-center {
    width: 100%;
    justify-content: flex-start;
  }
  .nav ul {
    flex-direction: column;
    width: 100%;
  }
  .nav ul li {
    margin: 0.5rem 0;
  }
  .nav ul li a {
    margin: 0;
    margin-top: 0;
  }
}

@media (max-width: 480px) {
  .nav {
    padding: 0.5rem 1rem;
  }
  .nav .nav-left img {
    height: 30px;
    margin-right: 0.5rem;
    margin-top: 0;
  }
  .nav .nav-left a,
  .nav .nav-right a {
    font-size: 0.85rem !important; /* Reduced font size */
    margin-top: 0; /* Ensure no top margin */
  }
  .nav ul li a {
    font-size: 0.85rem !important; /* Reduced font size */
    margin-top: 0; /* Ensure no top margin */
  }
}
