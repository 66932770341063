.job-details-container {
    background-color: #26262B;
    color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .job-details-container h1 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #7d4cdb;
  }
  
  .job-details-container p {
    font-size: 1em;
    margin: 10px 0;
  }
  
  .job-details-container p strong {
    color: #b0b0b0;
  }
  
  .job-details-container .highlight {
    color: #32CD32;
  }
  
  .job-details-container .job-posting-form__label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
    color: #b0b0b0;
  }
  
  .job-details-container .job-posting-form__field {
    margin-bottom: 15px;
  }
  
  .job-details-container .job-posting-form__input,
  .job-details-container .job-posting-form__textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #333333;
    border-radius: 4px;
    background-color: #333333;
    color: #ffffff;
    box-sizing: border-box;
  }
  
  .job-details-container .job-posting-form__textarea {
    height: 100px;
    resize: vertical;
  }
  
  .job-details-container .job-posting-form__checkbox {
    margin-right: 10px;
  }
  
  .job-details-container .job-posting-form__button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
  }
  
  .job-details-container .job-posting-form__prev-button {
    background-color: #ff6347;
    color: #ffffff;
  }
  
  .job-details-container .job-posting-form__next-button,
  .job-details-container .job-posting-form__submit-button {
    background-color: #32CD32;
    color: #ffffff;
  }
  
  .job-details-container .job-posting-form__prev-button:hover,
  .job-details-container .job-posting-form__next-button:hover,
  .job-details-container .job-posting-form__submit-button:hover {
    opacity: 0.9;
  }
  
  .job-details-container .job-posting-form__image-preview {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .job-details-container .job-posting-form__image-container {
    position: relative;
  }
  
  .job-details-container .job-posting-form__image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .job-details-container .job-posting-form__remove-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .job-details-container .job-posting-form__remove-button:hover {
    background-color: rgba(255, 255, 255, 1);
  }
  
  .job-details-container .job-posting-form__icon-container {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
    padding: 10px;
    border-radius: 4px;
    background-color: #333333;
    color: #ffffff;
  }
  
  .job-details-container .job-posting-form__icon-container.selected {
    background-color: #7d4cdb;
  }
  
  .job-details-container .job-posting-form__icon-container:hover {
    background-color: #444444;
  }
  
  .job-details-container .job-posting-form__icon {
    margin-right: 5px;
  }
  
  .job-details-container .job-posting-form__icon-text {
    font-size: 1em;
  }
  
  .job-details-container .job-posting-form__tool-item,
  .job-details-container .job-posting-form__tag-item {
    display: inline-flex;
    align-items: center;
    background-color: #333333;
    padding: 5px 10px;
    border-radius: 4px;
    margin: 5px;
  }
  
  .job-details-container .job-posting-form__tool-item span,
  .job-details-container .job-posting-form__tag-item span {
    margin-right: 10px;
  }
  
  .job-details-container .job-posting-form__tool-item button,
  .job-details-container .job-posting-form__tag-item button {
    background-color: transparent;
    border: none;
    color: #ff6347;
    cursor: pointer;
  }
  
  .job-details-container .job-posting-form__tool-item button:hover,
  .job-details-container .job-posting-form__tag-item button:hover {
    color: #ff0000;
  }
  