


.SignIn {
  width: 25rem;
  height: 23em;
  padding: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.7);

  
  
  position: absolute;
  top:40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.SignIn img{
  position: fixed;
  top: -150px;
  left: 50%;
  scale: 400%;
}
.SignIn h2 {
  color:rgb(255, 255, 255);
  position: absolute; /* Add position relative */
  margin-top: 8%;
  top: -10px;
  font-family:'Inter';
}

.SignIn-input {
  width: 100%;
  height: 2.5rem;
  margin-bottom: 1rem;
  outline: none;
  font-size: 1rem;
  padding: 4px;
  border: 0.5px solid rgb(172, 166, 166);
  border-radius: 5px;
  background-color: transparent;
  color: #efeff6;
  font-family:'Inter';
}

.SignIn-button {
  width: 100%;
  height: 2.5rem;
  margin-bottom: rem;
  outline: none;
  cursor: pointer;
  border: none;
  background-color: #6e5494;
  color: #efeff6;
  font-weight: 500;
  font-size: 1rem;
  border-radius: 5px;
  font-family:'Inter';
}

.SignIn-text {
  color: #eeeeee;
}
.google {
  
    transition: background-color .3s, box-shadow .3s;
      
    padding: 12px 16px 12px 42px;
    border: none;
    border-radius: 40px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    width: 22rem;
    color: #757575;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 12px 11px;
    
    &:hover {
      box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
    }
    
    &:active {
      background-color: #eeeeee;
    }
    
    &:focus {
      outline: none;
      box-shadow: 
        0 -1px 0 rgba(0, 0, 0, .04),
        0 2px 4px rgba(0, 0, 0, .25),
        0 0 0 3px #c8dafc;
    }
    
    &:disabled {
      filter: grayscale(100%);
      background-color: #ebebeb;
      box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
      cursor: not-allowed;
    }
  }


.icon {
  margin-left: 4px;
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.SignIn img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100%;
}

.SignIn h1 {
  color: #efeff6;
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
}
.SignIn label {
  text-align: left;
  color: #eeeeee;
  display: block;
}
.SecondBox {
  width: 25rem;
  height: auto;
  color: white; /* Text color set to white */
  padding: 2px; /* Example padding */
  margin-top: 20px; /* Example margin */
  position: fixed; /* Set position to fixed */
  z-index: 1; /* Set z-index to make it appear above other elements */
  border: 2px solid #6e5494; /* Purple border */
  border-radius: 5px;
  top: 41rem; /* Adjust top position */
  left: 50%;
  transform: translate(-50%, -50%);

  
  text-align: center;
  font-family:'Inter'
  
}
.forgot{
color: #ADD8E6;
text-decoration: underline;
}
.newacc {
  color: white; /* Set default text color */
}

.blue-text {
  color: #ADD8E6;
  text-decoration: underline;
}

.SignInBackground{
  width: 100%;
  height:66.7rem;
position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
}