.progress-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom:-30px;
    margin-top: 10px;
  }
  
  .progress-step {
    display: flex;
    align-items: center;
  }
  
  .circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #b0b0b0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .line {
    width: 50px;
    height: 4px;
    background-color: #b0b0b0;
    transition: background-color 0.3s ease;
  }
  
  .completed .circle,
  .completed .line {
    background-color: #32CD32;
  }
  
  .current .circle {
    background-color: #ff6347;
  }
  