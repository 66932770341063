.container {
    width: 25rem;
    height: 30rem;
    padding: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .container h2 {
    color: #ffffff;
    font-family: 'Inter';
    margin-bottom: 2rem;
  }
  
  .container label {
    text-align: left;
    color: #eeeeee;
    display: block;
  }
  
  .container input[type="text"],
  .container input[type="email"],
  .container input[type="password"] {
    width: 100%;
    height: 2.5rem;
    margin-bottom: 1rem;
    outline: none;
    font-size: 1rem;
    padding: 4px;
    border: 0.5px solid rgb(172, 166, 166);
    border-radius: 5px;
    background-color: transparent;
    color: #efeff6;
    font-family: 'Inter';
  }
  
  .container button {
    width: 100%;
    height: 2.5rem;
    margin-bottom: 1rem;
    outline: none;
    cursor: pointer;
    border: none;
    background-color: #6e5494;
    color: #efeff6;
    font-weight: 500;
    font-size: 1rem;
    border-radius: 5px;
    font-family: 'Inter';
  }
  
  .container button:hover {
    background-color: #5b487c;
  }
  
  .container .error {
    color: red;
    margin-bottom: 1rem;
    font-size: 1rem;
  }
  
  .SecondBox {
    width: 25rem;
    height: auto;
    color: white;
    padding: 2px;
    margin-top: 2rem;
    position: fixed;
    z-index: 1;
    border: 2px solid #6e5494;
    border-radius: 5px;
    top: 41rem;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-family: 'Inter';
  }
  
  .SecondBox .forgot {
    color: #ADD8E6;
    text-decoration: underline;
  }
  
  .SecondBox .newacc {
    color: white;
  }
  
  .SecondBox .blue-text {
    color: #ADD8E6;
    text-decoration: underline;
  }
  
  .SignInBackground {
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  