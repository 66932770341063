.homepagediv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000000;
}
.hometitletypetwo,
.hometitle {
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
  margin-top: 8rem;
  color: #ffffff;
  text-align: center;
}
.hometitletypetwo{
  margin-top: 6rem;
}

.subsectionone {
  display: flex;
  flex-direction: row;
  text-align: center;
  width: 60%;
}

.substackright,
.substackleft {
  display: flex;
  flex-direction: column;
}

.homeimg {
  width: 60%;
  height: 60%;
  align-self: center;
  margin-top: 1rem;
  border-radius: 10px;
  border: solid 1px #ffffff57;
}

.homebtns {
  display: flex;
  justify-content: center;
  width: 100%;
}
.waitbutton,
.homebtndev,
.homebtnclient {
  flex: 1;
  max-width: 150px;
  border-radius: 10px;
  background-color: white;
  color: #000000;
  border: 2px solid #7f4dca00;
  padding: 8px 20px;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 600;
  transition: all 0.3s;
  outline: none;
  cursor: pointer;
  margin: 0 40px;
  text-align: center;
  margin-top: 0px;
}
.waitbutton:hover,
.homebtndev:hover,
.homebtnclient:hover {
  color: #7d4cdb;
}
.waitbutton{
  margin-top: 20px;
  margin-bottom: 100px;
}

.subtitleone,
.subtitletwo {
  font-weight: 300;
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 1.5rem;
  margin-top: .5rem;
}

.icon-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.icon-container {
  display: flex;
  align-items: center;
  background-color: #33333300;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  color: #ffffff;
}

.icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: 2px solid #7d4cdb;
  margin-right: 10px;
  border-radius: 5px;
}

.icon-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
}

.icon-text {
  font-size: 1rem;
}

.icon-subheading {
  font-size: 0.875rem;
  color: #b0b0b0;
}

.vertical-line {
  width: 2px;
  height: 50px;
  background-color: #7d4cdb;
  margin: 0 10px;
}

.footer {
  width: 100%;
  padding: 20px;
  background-color: #26262600;
  color: #b0b0b0;
  text-align: center;
  margin-top: 20px;
  border: 2px solid #7e4cdb00;
  border-top-color: rgba(243, 243, 243, 0.233);
}


.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer-links a {
  color: #ffffff;
  margin: 0 10px;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #161616;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.popup h2, .popup p {
  color: #ffffff;
}
.popup button {
  background-color: #7d4cdb;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.popup form {
  display: flex;
  flex-direction: column;
}
.popup form label {
  margin-bottom: 10px;
  color: #ffffff;
}
.popup form input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #b0b0b0;
  border-radius: 5px;
}
